package com.picme.views

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.picme.*
import com.picme.actuals.deviceCanShareFiles
import com.picme.components.*
import com.picme.sdk2.Retainable
import com.picme.sdk2.generated.collection2.GetUploadResponse2
import com.picme.sdk2.generated.collection2.PCollection
import com.picme.sdk2.generated.collection2.PatchUploadBody
import com.picme.sdk2.generated.collection2.UploadId
import com.picme.views.Plan.content
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch


fun ViewWriter.editCaption(
    caption: Property<String>,
    captionDirty: Property<String>,
    currImage: Readable<ImageDisplayInfo>
) {
    dialogGeneric { close ->
        spacing = 2.rem
        col {
            spacing = 2.rem
            h4 {
                spacing = 0.dp
                content = "Enter a title"
                align = Align.Center
            }

            sizeConstraints(height = 3.5.rem) - fieldTheme - row {
                spacing = 0.5.rem
                expanding - textInput(fun TextInput.() {
                    hint = "title"
                    content bind captionDirty
                }).requestFocus()
                centered - button {
                    spacing = 0.rem
                    icon { source = PIcon.close.copy(width = 1.rem, height = 1.rem) }
                    onClick {
                        caption set ""
                        captionDirty set ""
                    }
                }
            }

            actionOrCancelSection(
                onCancel = close,
                actionButton = {
                    importantButton("Save", onClick = {
                        close()
                        session()?.collection2?.patchUpload(
                            collectionId = currentCollection()!!.collectionId,
                            uploadId = currImage().id.let(::UploadId),
                            body = PatchUploadBody(caption = Retainable(captionDirty()))
                        )
                        caption set captionDirty()
                    })
                },
            )
        }
    }
}

fun ViewWriter.imageInfo(
    imageDetails: Readable<GetUploadResponse2?>,
    caption: Property<String>,
    captionDirty: Property<String>,
    currImage: Readable<ImageDisplayInfo>
) {
    dialogGeneric { close ->
        spacing = 2.rem

        row {
            actionButtonNoText(
                displayIcon = PIcon.chevronleft
            ) { close() }
            centered - h3 {
                spacing = 0.dp
                content = "Photo Information"
                align = Align.Center
            }
        }
        col {
            spacing = 0.05.rem
            h4("Title")
            sizeConstraints(height = 3.5.rem) - fieldTheme - row {
                spacing = 0.5.rem
                expanding - textInput(fun TextInput.() {
                    hint = "title"
                    content bind captionDirty
                })
                centered - button {
                    spacing = 0.rem
                    icon { source = PIcon.close.copy(width = 1.rem, height = 1.rem) }
                    onClick {
                        caption set ""
                        captionDirty set ""
                    }
                }
            }
        }
        col {
            ::exists { imageDetails()?.upload != null }
            spacing = 0.05.rem
            h4("Upload Date")
            text {
                ::content { formatIsoDate(imageDetails()?.upload?.uploadTime.toString()) }
            }
        }
        col {
            ::exists { imageDetails()?.uploader?.uploaderName != null }
            spacing = 0.05.rem
            h4("Uploader")
            text {
                ::content { imageDetails()?.uploader?.uploaderName ?: "" }
            }
        }
        col {
            ::exists { imageDetails()?.upload?.originalFilename != null }
            spacing = 0.05.rem
            h4("File Name")
            text {
                ::content { imageDetails()?.upload?.originalFilename ?: "" }
            }
        }
        col {
            ::exists { imageDetails()?.upload?.bytes != null }
            spacing = 0.05.rem
            h4("File Size")
            text {
                ::content { formatBytes(imageDetails()?.upload?.bytes ?: 0) }
            }
        }

        actionOrCancelSection(
            onCancel = close,
            actionButton = {
                importantButton("Save", onClick = {
                    session()?.collection2?.patchUpload(
                        collectionId = currentCollection()!!.collectionId,
                        uploadId = currImage().id.let(::UploadId),
                        body = PatchUploadBody(caption = Retainable(captionDirty()))
                    )
                    caption set captionDirty()
                    close()
                })
            },
        )

    }
}


fun ViewWriter.imageActions(
    atBottom: Boolean,
    currImage: Readable<ImageDisplayInfo>,
    details: Readable<GetUploadResponse2?>,
    confirmDelete: suspend () -> Unit,
) {
    row {
        centered - actionButtonNoText(PIcon.trash) {
            showConfirmDialog(
                title = "Confirm Delete",
                content = "This item will be deleted from the collection.",
                confirmLabel = "Delete",
                onConfirm = confirmDelete
            )
        }
        space(multiplier = 2.0) { exists = !atBottom }
        expanding - space() { exists = atBottom }

        centered - actionButtonNoText(PIcon.download) {
            val imageToDownload = currImage()
            openProgressModal(
                title = "Downloading file",
                execute = {
                    launchGlobal {
                        image = imageToDownload.thumbnail
                        try {
                            individualItemProgress = 0.25f
                            val filename = imageToDownload.id.validDownloadableName()
                            val url = getDetailsUri(imageToDownload.id.let(::UploadId))
                            ExternalServices.download(filename, url, DownloadLocation.Pictures) {
                                individualItemProgress = it
                            }
                            individualItemProgress = 0.5f
                            delay(1000)
                            individualItemProgress = 0.75f
                            delay(1000)

                        } catch (e: Exception) {
                            e.printStackTrace()
                            showToast("Download failed. Check PicMe's Camera Roll access in Settings.")
                        }
                    }

                }, onComplete = {
                    showToast(
                        when (Platform.current) {
                            Platform.iOS -> "File saved to Camera Roll"
                            else -> "File downloaded"
                        }
                    )
                }
            )
        }

        space(multiplier = 2.0) { exists = !atBottom && deviceCanShareFiles() }
        expanding - space { exists = atBottom && deviceCanShareFiles() }
        stack {
            exists = deviceCanShareFiles()
            centered - actionButtonNoText(PIcon.share) {
                val copy = getBlob(details()?.getDetailsUri ?: "")
                ExternalServices.share(listOf(currImage().id.validDownloadableName() to copy))
            }
        }

        space(multiplier = 2.0) { exists = !atBottom }
        expanding - space() { exists = atBottom }

        centered - iconButtonTheme - menuButton {
            ::exists { sharedSuspending { currentCollection()?.let { ownsPCollection(it) } ?: false }() }
            icon(PIcon.more, "More Actions")
            preferredDirection =
                if (atBottom) PopoverPreferredDirection.aboveLeft else PopoverPreferredDirection.belowLeft
            requireClick = true
            opensMenu {
                popoverWrapper {
                    col {
                        popoverItemTheme - button {
                            row {
                                sizeConstraints(minWidth = 1.5.rem, minHeight = 1.5.rem) - centered - icon {
                                    source = PIcon.copy
                                }
                                space {}
                                centered - h6 {
                                    content = "Copy to another collection"
                                }
                            }
                            onClick {
                                openCopyMenu(listOf(UploadId(currImage().id)))
                                closePopovers()
                            }
                        }

                        popoverItemTheme - button {
                            dynamicTheme {
                                if (details()?.upload?.mimeType?.raw?.contains("video") == true) DisabledSemantic else null
                            }
                            row {
                                sizeConstraints(minWidth = 1.5.rem, minHeight = 1.5.rem) - centered - icon {
                                    source = PIcon.image
                                }
                                space {}
                                centered - h6 {
                                    content = "Use as Collection Image"
                                }
                            }
                            onClick {
                                if (details()?.upload?.mimeType?.raw?.contains("video") == true) return@onClick
                                val uri = details()?.getDetailsUri ?: return@onClick
                                val copy = getBlob(uri)
                                val coll = currentCollection() ?: return@onClick
                                session()!!.collection2.putCollectionCoverPhoto(
                                    collectionId = coll.collectionId,
                                    body = RequestBodyBlob(content = copy),
                                    tempUri = uri,
                                    onProgress = {}
                                )
                                closePopovers()
                            }
                        }
                    }
                }
            }
        }

        space(multiplier = 2.0) { exists = !atBottom }
    }
}

suspend fun getBlob(url: String): Blob {
//    https://stackoverflow.com/questions/65191708/chrome-browser-and-cors-issue-when-caching-present-in-request-headers
    return com.lightningkite.kiteui.fetch(
        url, method = HttpMethod.GET,
        httpHeaders().apply {
            append("mode", "cors")
            append("cache", "no-cache")
            append("Cache-Control", "no-cache")
        },
    ).blob()
}