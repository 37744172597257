package com.picme.sso

import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.launchGlobal
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.reactive.Constant
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewModifiable
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.stack
import com.picme.UnauthScreen
import com.picme.fullScreenLoading
import com.picme.navigateToCollOrLanding
import com.picme.sdk2.Session
import com.picme.sdk2.generated.authentication.UserAuthenticated
import com.picme.sdk2.unauthApi
import com.picme.session
import com.picme.views.LoginOrSignUp

@Routable("/sso-google")
class GoogleSSOLanding : Page, UnauthScreen {
    @QueryParameter
    val access = Property("")

    @QueryParameter
    val refresh = Property("")

    override fun ViewWriter.render2(): ViewModifiable {
        return stack {
            launchGlobal {
                val userAuth = unauthApi().authenticationHandler.refreshUserAuthentication(
                    refresh()
                ) { access() }.successfulAuthentication?.authenticatedUser

                if (userAuth == null) {
                    mainScreenNavigator.reset(LoginOrSignUp(false))
                } else {
                    val newSession = Session(
                        unauthApi(), UserAuthenticated(
                            accessToken = access(),
                            tokenType = "access_token",
                            refreshToken = refresh(),
                            expiresInSeconds = 100
                        ), userAuth
                    )
                    println(newSession.collection2.listCollectionsLive().all())
                    session set newSession
                    navigateToCollOrLanding()
                }
            }

            fullScreenLoading(Constant(true))
        }
    }

}