package com.picme.sso

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.reactive
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import com.picme.Resources
import com.picme.separatorTheme
import com.picme.views.selectedBackend

fun ViewWriter.startSSO() {
    val googleEnabled = Property(false)
    val appleEnabled = Property(false)

    reactive {
        googleEnabled.value = selectedBackend().contains("dev")
        appleEnabled.value = selectedBackend().contains("dev")
    }

    col {
        ::exists { googleEnabled() || appleEnabled() }
        sizeConstraints(height = 2.rem) - row {
            spacing = 8.dp
            gravity(Align.Stretch, Align.Center) - expanding - separatorTheme - separator()
            centered - subtext("OR")
            gravity(Align.Stretch, Align.Center) - expanding - separatorTheme - separator()
        }
        externalLink {
            ::exists { googleEnabled() }
            spacing = 0.dp
            themeChoice += ThemeDerivation { it.copy(cornerRadii = CornerRadii.ForceConstant(100.rem)).withoutBack }
            image { source = Resources.ssoLoginGoogle }
            ::to { "https://${selectedBackend().replace("https://", "")}/a/SsoHandler/sso/google?configName=&responseUriId=&setTosRead=true" }
        }
        externalLink {
            ::exists { appleEnabled() }
            spacing = 0.dp
            themeChoice += ThemeDerivation { it.copy(cornerRadii = CornerRadii.ForceConstant(100.rem)).withoutBack }
            image { source = Resources.ssoLoginApple }
            ::to { "https://${selectedBackend().replace("https://", "")}/a/SsoHandler/sso/apple?configName=&responseUriId=&setTosRead=true" }
        }
    }
}
