package com.picme.sdk2.generated.authentication

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


object AuthenticationHandlerEndpointsSerializer : KSerializer<AuthenticationHandlerEndpoints> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("AuthenticationHandlerApi", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: AuthenticationHandlerEndpoints) {
        encoder.encodeString(value.urlWithTrailingSlash)
    }

    override fun deserialize(decoder: Decoder): AuthenticationHandlerEndpoints {
        val string = decoder.decodeString()
        return AuthenticationHandlerEndpoints(string)
    }
}


@Serializable(with = AuthenticationHandlerEndpointsSerializer::class)
data class AuthenticationHandlerEndpoints(val urlWithTrailingSlash: String) {

    val url = urlWithTrailingSlash.trimEnd('/')
/** Continue a password reset operation (step two). **/
    suspend fun continuePasswordReset(
        emailOrPhoneNumber: String,
        confirmationCode: String,
        newPassword: String,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/user/username/${emailOrPhoneNumber.toParamString()}/continue-password",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "confirmationCode" to confirmationCode.toParamString(),
            "newPassword" to newPassword.toParamString(),
        ).filter { it.value != "null" },
    )
/** Continues the sign-up process for a user (step two). **/
    suspend fun continueSignUpUser(
        emailOrPhoneNumber: String,
        verificationCode: String,
        setTosRead: Boolean,
        name: String?,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/user/username/${emailOrPhoneNumber.toParamString()}/continue-signup",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "verificationCode" to verificationCode.toParamString(),
            "setTosRead" to setTosRead.toParamString(),
            "name" to name.toParamString(),
        ).filter { it.value != "null" },
    )
/** Requests that an account verification message be resent to the specified email or phone number. **/
    suspend fun resendAccountVerification(
        emailOrPhoneNumber: String,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/user/username/${emailOrPhoneNumber.toParamString()}/verification",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Requests a password reset for a specified user. **/
    suspend fun requestPasswordReset(
        emailOrPhoneNumber: String,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/user/username/${emailOrPhoneNumber.toParamString()}/password",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun signUpUser(
        emailOrPhoneNumber: String,
        password: String?,
        setTosRead: Boolean?,
        referrerUserId: UserId?,
        userName: String?,
        accessToken: suspend () -> String? = { null }
    ): SignUpUserResponse = fetch(
        url = "$url/user/username/${emailOrPhoneNumber.toParamString()}/signup",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "password" to password.toParamString(),
            "setTosRead" to setTosRead.toParamString(),
            "referrerUserId" to referrerUserId.toParamString(),
            "userName" to userName.toParamString(),
        ).filter { it.value != "null" },
    )
/** Starts authenticating a user. **/
    suspend fun startAuthentication(
        emailOrPhoneNumber: String,
        password: String,
        accessToken: suspend () -> String? = { null }
    ): StartAuthenticationResponse = fetch(
        url = "$url/session/username/${emailOrPhoneNumber.toParamString()}",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "password" to password.toParamString(),
        ).filter { it.value != "null" },
    )
/** Checks a username to see if there is already a user using it. **/
    suspend fun checkUsername(
        emailOrPhoneNumber: String,
        accessToken: suspend () -> String? = { null }
    ): CheckUsernameResponse = fetch(
        url = "$url/user/username/${emailOrPhoneNumber.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Gets the information needed to upload a new user profile picture.
            Note that this function does not actually upload the picture.  
            It just returns the URL to use to upload the picture, and removes any old profile picture in the process. **/
    suspend fun getUserProfileUploadUrl(
        contentType: String,
        accessToken: suspend () -> String? = { null }
    ): GetUserProfileUploadUrlResponse = fetch(
        url = "$url/session/user/profile-picture/urls",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "contentType" to contentType.toParamString(),
        ).filter { it.value != "null" },
    )
/** Deletes the profile picture for the authenticated user. **/
    suspend fun deleteUserProfilePicture(
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/session/user/profile-picture",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun getUserProfileInfo(
        userId: UserId,
        collectionId: CollectionId?,
        accessToken: suspend () -> String? = { null }
    ): GetUserProfileInfoResponse = fetch(
        url = "$url/user/id/${userId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "collectionId" to collectionId.toParamString(),
        ).filter { it.value != "null" },
    )
/** Gets the URLs needed to access and update client-defined user state data. **/
    suspend fun getUserStateDataUrls(
        accessToken: suspend () -> String? = { null }
    ): GetUserStateDataUrlsResponse = fetch(
        url = "$url/session/user/state/urls",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Sets user attributes. **/
    suspend fun setUserAttributes(
        name: String?,
        setTosRead: Boolean,
        accessToken: suspend () -> String? = { null }
    ): SetUserAttributesResponse = fetch(
        url = "$url/session/user/attributes",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "name" to name.toParamString(),
            "setTosRead" to setTosRead.toParamString(),
        ).filter { it.value != "null" },
    )
/** Updates the Firebase Cloud Messaging project configuration. **/
    suspend fun updateFirebaseCloudMessagingProjectConfig(
        config: FirebaseCloudMessagingProjectConfig,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/config/firebase-project",
        method = HttpMethod.PUT,
        accessToken = accessToken,
        parameters = mapOf(
            "config" to config.toParamString(),
        ).filter { it.value != "null" },
    )
/** Gets the Firebase Cloud Messaging projecct configuration needed by the client. **/
    suspend fun getFirebaseCloudMessagingProjectConfig(
        accessToken: suspend () -> String? = { null }
    ): GetFirebaseCloudMessagingProjectConfigResponse = fetch(
        url = "$url/config/firebase-project",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Changes the password for the authenticated user. **/
    suspend fun changeUserPassword(
        oldPassword: String,
        newPassword: String,
        accessToken: suspend () -> String? = { null }
    ): ChangeUserPasswordResponse = fetch(
        url = "$url/session/user/password",
        method = HttpMethod.PUT,
        accessToken = accessToken,
        parameters = mapOf(
            "oldPassword" to oldPassword.toParamString(),
            "newPassword" to newPassword.toParamString(),
        ).filter { it.value != "null" },
    )
/** Updates the Firebase Cloud Messaging project configuration. **/
    suspend fun updateFirebaseCloudMessagingAppConfig(
        config: FirebaseCloudMessagingAppConfig,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/config/firebase-app",
        method = HttpMethod.PUT,
        accessToken = accessToken,
        parameters = mapOf(
            "config" to config.toParamString(),
        ).filter { it.value != "null" },
    )
/** Gets the Firebase Cloud Messaging projecct configuration needed by the client. **/
    suspend fun getFirebaseCloudMessagingAppConfig(
        accessToken: suspend () -> String? = { null }
    ): GetFirebaseCloudMessagingAppConfigResponse = fetch(
        url = "$url/config/firebase-app",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Gets the user information for the authenticated user. **/
    suspend fun getMyUserInfo(
        accessToken: suspend () -> String? = { null }
    ): GetMyUserInfoResponse = fetch(
        url = "$url/session/user/info",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Refreshes user authentication using a refresh token. **/
    suspend fun refreshUserAuthentication(
        refreshToken: String,
        accessToken: suspend () -> String? = { null }
    ): RefreshUserAuthenticationResponse = fetch(
        url = "$url/session-refresh",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "refreshToken" to refreshToken.toParamString(),
        ).filter { it.value != "null" },
    )
/** Creates a Invite code object for the calling user to refer new users to PicMe.
            The Invite code will be owned by the calling user and will be hierarchically linked to the specified user.
            The link type for the activated link is <see cref="P:SimpleDatabase.AuthLinkRelationshipTypes.ReferralLinkType" /> with a link relationship of type <see cref="T:SimpleDatabase.ReferralType" />. **/
    suspend fun createReferralInviteCode(
        name: String,
        clientInformation: String,
        accessToken: suspend () -> String? = { null }
    ): CreateInviteCodeResponse = fetch(
        url = "$url/referralInvite",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "name" to name.toParamString(),
            "clientInformation" to clientInformation.toParamString(),
        ).filter { it.value != "null" },
    )
/** Sends a test email to the caller's email account. **/
    suspend fun sendTestEmail(
        body: String,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/sendTestEmail",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
        ),
        body = body,
    )
/** Deletes the authenticated user account and everything it owns. **/
    suspend fun deleteUser(
        accessToken: suspend () -> String? = { null }
    ): DeleteUserResponse = fetch(
        url = "$url/session/user",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Merges the currently-authenticated guest account into the specified full account, returning new tokens for the full account.
            The guest account's data will be removed, so the tokens used to make this call will not be valid after a successful call to this API. **/
    suspend fun mergeIntoAccount(
        targetAccountEmailOrPhoneNumber: String,
        targetAccountPassword: String,
        accessToken: suspend () -> String? = { null }
    ): MergeIntoAccountResponse = fetch(
        url = "$url/mergeInto",
        method = HttpMethod.PATCH,
        accessToken = accessToken,
        parameters = mapOf(
            "targetAccountEmailOrPhoneNumber" to targetAccountEmailOrPhoneNumber.toParamString(),
            "targetAccountPassword" to targetAccountPassword.toParamString(),
        ).filter { it.value != "null" },
    )
/** Lists users referred by the currently-authenticated user. **/
    suspend fun listMyReferrals(
        accessToken: suspend () -> String? = { null }
    ): ListReferralsResponse = fetch(
        url = "$url/referrals",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Checks to see if a user is authenticated and if they are, gets information about the user. **/
    suspend fun checkUserAuthentication(
        accessToken: suspend () -> String? = { null }
    ): CheckUserAuthenticationResponse = fetch(
        url = "$url/session",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Invalidates any tokens for the authenticated user. **/
    suspend fun invalidateUserTokens(
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/session",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Checks for ownership and rights on the specified record. **/
    suspend fun getRightsOn(
        recordGlobalId: RecordGlobalId,
        accessToken: suspend () -> String? = { null }
    ): GetRightsOnResponse = fetch(
        url = "$url/rights",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "recordGlobalId" to recordGlobalId.toParamString(),
        ).filter { it.value != "null" },
    )
/** Authenticates as a guest (creates a new guest account). **/
    suspend fun authenticateAsGuest(
        setTosRead: Boolean,
        userName: String?,
        accessToken: suspend () -> String? = { null }
    ): AuthenticateAsGuestResponse = fetch(
        url = "$url/guest",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "setTosRead" to setTosRead.toParamString(),
            "userName" to userName.toParamString(),
        ).filter { it.value != "null" },
    )
}

