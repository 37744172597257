package com.picme.sdk2.generated

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*




import com.picme.sdk2.generated.ad.AdHandlerEndpoints
import com.picme.sdk2.generated.authentication.AuthenticationHandlerEndpoints
import com.picme.sdk2.generated.collection2.CollectionHandler2Endpoints
import com.picme.sdk2.generated.discussion.DiscussionHandlerEndpoints
import com.picme.sdk2.generated.notification.NotificationHandlerEndpoints
import com.picme.sdk2.generated.poll.PollHandlerEndpoints
import com.picme.sdk2.generated.qrgeneration.QrGenerationHandlerEndpoints
import com.picme.sdk2.generated.sso.SsoHandlerEndpoints


@Serializable
data class ApiEndpoints(
    val adHandler: AdHandlerEndpoints,
    val authenticationHandler: AuthenticationHandlerEndpoints,
    val collectionHandler2: CollectionHandler2Endpoints,
    val discussionHandler: DiscussionHandlerEndpoints,
    val notificationHandler: NotificationHandlerEndpoints,
    val pollHandler: PollHandlerEndpoints,
    val qrGenerationHandler: QrGenerationHandlerEndpoints,
    val ssoHandler: SsoHandlerEndpoints,
)
