package com.picme.installrefer

import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewWriter
import com.picme.UnauthScreen
import com.picme.currentCollection
import com.picme.sdk2.generated.collection2.PCollection
import com.picme.sessionRefreshToken
import com.picme.views.CollectionLanding
import kotlinx.coroutines.launch

@Routable("/install-refer-finish")
class InstallReferFinishScreen : Screen, UnauthScreen {

    @QueryParameter("token")
    val refreshToken = Property<String?>(null)

    @QueryParameter("collection")
    val lastViewedCollection = Property<PCollection?>(null)

    override fun ViewWriter.render() {
        launch {
            // For security, only set a token and collection when none are already set (i.e. on a fresh install)
            // Otherwise, malicious actors could trick users into uploading photos to an account that is not their own
            if (sessionRefreshToken().isNullOrEmpty() && currentCollection() == null) {
                sessionRefreshToken.value = refreshToken()
                currentCollection.value = lastViewedCollection()
            }
            screenNavigator.replace(CollectionLanding)
        }
    }
}