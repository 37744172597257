package com.picme.installrefer

import com.lightningkite.kiteui.Platform
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.probablyAppleUser
import com.lightningkite.kiteui.reactive.Constant
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.usesTouchscreen
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.buttonTheme
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.important
import com.picme.UnauthScreen
import com.picme.components.authScreen
import com.picme.components.secondaryButton
import com.picme.currentCollection
import com.picme.sessionRefreshToken
import com.picme.views.CollectionLanding
import com.picme.views.frontendUrl
import kotlinx.coroutines.launch

@Routable("/install-refer-start")
object InstallReferStartScreen : Screen, UnauthScreen {
    override fun ViewWriter.render() {
        launch {
            if (!Platform.probablyAppleUser || !Platform.usesTouchscreen) {
                screenNavigator.replace(CollectionLanding)
            }
        }
        authScreen("Continue in app", Constant(false)) {
            text {
                content = "Do you want to automatically sign-in to the app and continue where you left off in Safari?"
                align = Align.Center
            }
            buttonTheme - important - externalLink {
                centered - row {
                    spacing = 0.rem
                    space()
                    centered - h6 { content = "Continue in app" }
                    space()
                }
                ::to {
                    // We switch to a different domain in order to get Safari to follow this link as a universal link and open in the app
                    val base = if (frontendUrl() == "https://app.picme.com") "https://staging.app.picme.com" else "https://app.picme.com"
                    val installReferFinishPath = screenNavigator.routes.render(InstallReferFinishScreen().apply {
                        refreshToken.value = sessionRefreshToken()
                        lastViewedCollection.value = currentCollection()
                    })?.urlLikePath?.render()
                    "$base/$installReferFinishPath"
                }
            }
            secondaryButton {
                label = "Cancel"
                button.onClick {
                    screenNavigator.replace(CollectionLanding)
                }
            }
        }
    }
}