package com.picme.layout


import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.*
import com.picme.sdk2.Retainable
import com.picme.sdk2.generated.collection2.PatchCollectionBody
import com.picme.views.CollSubPage
import com.picme.views.CollectionLanding
import com.picme.views.trashActions
import kotlinx.coroutines.launch


fun ViewWriter.toolbar() {
    val showToolbar = sharedSuspending {
        val curr = screenNavigator.currentScreen()
        if (curr is NoTopBar) return@sharedSuspending false
        if (curr is IncludeTopBar) return@sharedSuspending true

        if (curr is CollectionLanding) return@sharedSuspending (session()?.isVerifiedAccount() == true)

        (curr !is UnauthScreen && curr !is PicmeDialog)
    }
    bar - row {
        ::exists { showToolbar() && currentCollection() != null }
        spacing = 0.75.rem
        collectionListMenu()


        val title = shared { screenNavigator.currentScreen()?.title?.await() ?: "..." }


        expanding - stack {
            atStart - button {
                themeChoice += ThemeDerivation { it.copy(cornerRadii = CornerRadii.Constant(1.rem)).withoutBack }
                h4 {
                    ::exists { title().isNotEmpty() }
                    ::content { title() }
                }
                onClick {
                    editTitleDialog()
                }
            }
        }

        centered - stack {
            ::exists{ title().isEmpty() }
            picmeIconDisplay()
        }

        val actionSection = shared {
            if (isSmallScreen()) ActionSection.None
            else if (CollectionState.currView() == CollSubPage.Trash) ActionSection.Trash
            else ActionSection.Collection
        }

        unpadded - stack {
            ::exists { actionSection() != ActionSection.None }
            spacing = 0.rem
            swapView {
                swapping(
                    current = { actionSection() },
                    views = {
                        when (it) {
                            ActionSection.Collection -> collectionButtonsTop()
                            ActionSection.Trash -> trashActions(false)
                            ActionSection.None -> {}
                        }
                    },
                )
            }
        }

        atEnd - userInfoPopover()
    }


    bar - row {
        ::exists { showToolbar() && currentCollection() == null }
        spacing = 0.75.rem
        collectionListMenu()

        expanding - space { }
        centered - stack {
            picmeIconDisplay()
        }

        expanding - space { }
        userInfoPopover()
    }
    separatorTheme - separator { ::exists { showToolbar() } }
}


enum class ActionSection { Collection, Trash, None }

fun ViewWriter.editTitleDialog() {
    val collName = Property("")
    launch {
        collName set (currentCollection()?.name ?: "")
    }
    dialogGeneric { close ->
        spacing = 2.rem
        col {
            spacing = 0.05.rem
            h4 {
                spacing = 0.dp
                content = "Collection Name"
                align = Align.Center
            }
            val tf: TextField
            styledTextField {
                spacing = 0.dp
                tf = this.field
                content bind collName
                hint = "Name"
            }
            actionOrCancelSection(
                onCancel = close,
                actionButton = {
                    importantButton("Save", enabled = shared { collName().isNotEmpty() }) {
                        currentCollection()?.collectionId?.let { id ->
                            session.awaitNotNull().collection2.patchCollection(
                                collectionId = id,
                                body = PatchCollectionBody(
                                    name = Retainable(collName()),
                                )
                            )
                        }
                        close()
                    }
                },
            )
        }
    }
}

