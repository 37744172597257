package com.picme.sdk2.generated.authentication

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


/** A record containing the response to a request to authenticate as a guest (empty). **/

/** A record containing the response to a request to continue a password reset (empty). **/

/** A record containing the response to a request to resend an account verification code (empty). **/

/** A record containing the response to a request to request a password reset (empty). **/

/** A record containing the response to a request to sign up a user. **/
@Serializable
data class SignUpUserResponse(
    val userId: UserId = UserId(""), 
    val assignedPassword: String? = null, 
)

/** A record containing the response to a request to start authentication. **/
@Serializable
data class StartAuthenticationResponse(
    val successfulAuthentication: AuthenticationComplete? = null, 
    val moreAuthenticationRequired: MoreAuthenticationRequired? = null, 
)

/** A record whose presence indicates that the user will need to set a new password. **/
@Serializable
data class MoreAuthenticationRequired(
    val newPasswordRequired: CollectNewPassword? = null, 
)

/** A record containing data needed to set a new password. **/
@Serializable
data class CollectNewPassword(
    val token: NewPasswordChallengeToken = NewPasswordChallengeToken(""), 
)

/** A struct that holds a password challenge token. **/
@Serializable
@JvmInline
value class NewPasswordChallengeToken(val raw: String = "")

/** A record containing the response to a request to authenticate or refresh authentication. **/
@Serializable
data class AuthenticationComplete(
    val authenticated: UserAuthenticated = UserAuthenticated(), 
    val authenticatedUser: UserData = UserData(), 
)

/** A record containing a selective copy of information about a user. **/
@Serializable
data class UserData(
    val userId: UserId = UserId(""), 
    val userGlobalId: RecordGlobalId = RecordGlobalId(""), 
    val email: String = "", 
    val name: String = "", 
    val phoneNumber: String = "", 
    val emailVerified: Boolean = false, 
    val phoneNumberVerified: Boolean = false, 
    val termsOfServiceRead: Boolean = false, 
)

/** A record containing authentication information. **/
@Serializable
data class UserAuthenticated(
    val accessToken: String = "", 
    val tokenType: String = "", 
    val refreshToken: String = "", 
    val expiresInSeconds: Int32 = 0, 
)

/** A record containing the response to a request to check a username. **/
@Serializable
data class CheckUsernameResponse(
    val code: String = "", 
    val status: String = "", 
    val verified: Boolean = false, 
)

/** A record containing the data needed to update a user's profile picture. **/
@Serializable
data class GetUserProfileUploadUrlResponse(
    val profilePictureLocation: String = "", 
)

/** A record containing the response to a request to delete a user's profile picture (empty). **/

/** A record containing the response to a request to get a user's profile information from another user's account, such as for listing collection guests or users referred by a user. **/
@Serializable
data class GetUserProfileInfoResponse(
    val name: String = "", 
    val profilePicture: String = "", 
)

/** A record containing the response to a request to get URLs for getting and putting user state data. **/
@Serializable
data class GetUserStateDataUrlsResponse(
    val getUserStateData: Uri = "", 
    val putUserStateData: Uri = "", 
)

/** A record containing the response to a request to set user attributes. **/
@Serializable
data class SetUserAttributesResponse(
    val reverificationRequired: Boolean = false, 
)


@Serializable
data class FirebaseCloudMessagingProjectConfig(
    val type: String = "", 
    val projectId: String = "", 
    val privateKeyId: String = "", 
    val privateKey: String = "", 
    val clientEmail: String = "", 
    val clientId: String = "", 
    val authUri: String = "", 
    val tokenUri: String = "", 
    val authProviderX509CertUrl: String = "", 
    val clientX509CertUrl: String = "", 
)

/** A record containing the response to a request to set the Firebase Cloud Messaging project configuration. **/

/** A record containing the response to a request to get the Firebase Cloud Messaging project configuration. **/
@Serializable
data class GetFirebaseCloudMessagingProjectConfigResponse(
    val firebaseProjectId: String = "", 
)

/** A record containing the response to a request to change a user's password. **/
@Serializable
data class ChangeUserPasswordResponse(
    val success: Boolean = false, 
)


@Serializable
data class FirebaseCloudMessagingAppConfig(
    val serverKey: String = "", 
    val senderId: String = "", 
    val projectId: String = "", 
    val appId: String = "", 
    val apiKey: String = "", 
)

/** A record containing the response to a request to set the Firebase Cloud Messaging app configuration. **/

/** A record containing the response to a request to get the Firebase Cloud Messaging app configuration. **/
@Serializable
data class GetFirebaseCloudMessagingAppConfigResponse(
    val firebaseProjectId: String = "", 
)

/** A record containing the response to a request to get a user's profile information. **/
@Serializable
data class GetMyUserInfoResponse(
    val userData: UserData = UserData(), 
    val profilePicture: String = "", 
    val authenticationMethods: List<String> = listOf(), 
)

/** A record containing the response to a request to refresh authentication using a refresh token. **/
@Serializable
data class RefreshUserAuthenticationResponse(
    val successfulAuthentication: AuthenticationComplete? = null, 
    val moreAuthenticationRequired: MoreAuthenticationRequired? = null, 
)

/** A non-numeric enumeration of link relationship types related to the authentication/authorization system. **/
object AuthLinkRelationshipTypesSerializer: KSerializer<AuthLinkRelationshipTypes> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("authentication.AuthLinkRelationshipTypes", PrimitiveKind.STRING)
    
    override fun deserialize(decoder: Decoder): AuthLinkRelationshipTypes {
        return AuthLinkRelationshipTypes.byValue[decoder.decodeString()] ?: AuthLinkRelationshipTypes.values().first()
    }
    
    override fun serialize(encoder: Encoder, value: AuthLinkRelationshipTypes) {
        encoder.encodeString(value.value)
    }
}
@Serializable(AuthLinkRelationshipTypesSerializer::class)
enum class AuthLinkRelationshipTypes(val value: String) {
/** A <see cref="T:SimpleDatabase.LinkRelationshipType" /> for a link that indicates that one user referred another to the platform.
            The <see cref="T:SimpleDatabase.LinkRelationship" /> for this type is constructed using <see cref="M:SimpleDatabase.LinkRelationship.FromEnum``1(``0)" /> with the enum type <see cref="T:SimpleDatabase.ReferralType" />. **/
    ReferralLinkType("i,R"),
    ;
    companion object {
        val byValue = AuthLinkRelationshipTypes.values().associateBy { it.value }
    }
}

/** An enumeration of type of referrals. **/
object ReferralTypeSerializer: KSerializer<ReferralType> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("authentication.ReferralType", PrimitiveKind.INT)
    
    override fun deserialize(decoder: Decoder): ReferralType {
        return ReferralType.byValue[decoder.decodeInt()] ?: ReferralType.values().first()
    }
    
    override fun serialize(encoder: Encoder, value: ReferralType) {
        encoder.encodeInt(value.value)
    }
}
@Serializable(ReferralTypeSerializer::class)
enum class ReferralType(val value: Int) {
/** The referral is a normal referral (no other types yet). **/
    Normal(0),
    ;
    companion object {
        val byValue = ReferralType.values().associateBy { it.value }
    }
}

/** A record containing the response to a request to send a test email. **/

/** A record containing the response to a request to delete a user. **/
@Serializable
data class DeleteUserResponse(
    val userId: UserId = UserId(""), 
)

/** A record containing the response to the <see cref="M:PicMeApi.AuthenticationApis.MergeIntoAccount(SimpleDatabase.Database,CloudFileSystems.ICloudFileSystem,SimpleDatabase.Auth,System.String,System.String,PicMeApi.UserDataDetails,System.Net.IPAddress)" /> API. **/
@Serializable
data class MergeIntoAccountResponse(
    val successfulAuthentication: AuthenticationComplete? = null, 
    val moreAuthenticationRequired: MoreAuthenticationRequired? = null, 
)

/** A record containing the response to a request to list referrals. **/
@Serializable
data class ListReferralsResponse(
    val referrals: List<Referral> = listOf(), 
)

/** A record containing information about a referral. **/
@Serializable
data class Referral(
    val userId: UserId = UserId(""), 
    val emailOrPhoneNumber: String = "", 
    val name: String = "", 
)

/** A record containing the response to a request to check a user's authentication. **/
@Serializable
data class CheckUserAuthenticationResponse(
    val code: String = "", 
    val user: UserData = UserData(), 
)

/** A record containing the response to a request to invalidate a user's tokens (empty). **/

/** A record containing indicating the rights a user has on a record. **/
@Serializable
data class GetRightsOnResponse(
    val ownership: LinkRelationship? = null, 
    val rights: LinkRelationship? = null, 
)

/** A record containing the response to a request to create a new guest user account. **/
@Serializable
data class AuthenticateAsGuestResponse(
    val guestId: UserId = UserId(""), 
    val guestGlobalId: RecordGlobalId = RecordGlobalId(""), 
    val authenticated: UserAuthenticated = UserAuthenticated(), 
)

